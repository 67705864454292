import outline from "@assets/Images/laurel_outline.png";
import bg from "@assets/icons/races_cta_bg.png";
import { WeeklyIcon } from "@assets/svgs/WeeklyIcon";
import useLanguage from "@hooks/useLanguage";
import useWallet from "@hooks/useWallet";
import { timeLeft } from "@lib/utils";
import { racesAtom } from "@store/global";
import st from "@styles/components/RacesCTA.module.scss";
import { addMonths, nextSaturday, setHours, setMinutes, setSeconds, startOfMonth } from "date-fns";
import { useAtomValue } from "jotai";
import Image from "next/image";
import Link from "next/link";
import React, { memo, useMemo } from "react";
type RacesCTAProps = Readonly<{
  type: "weekly" | "monthly";
  mini?: boolean;
  currentTime: number;
}>;
const RacesCTA = ({
  type,
  mini,
  currentTime
}: RacesCTAProps) => {
  const L = useLanguage(["races", "Rewards"]);
  const {
    activeRate
  } = useWallet();
  const data = useAtomValue(racesAtom);
  const prizes = useMemo(() => {
    const prizeWeekly = +data?.weekly?.total_rewards / 1000;
    const prizeMonthly = +data?.monthly?.total_rewards / 1000;
    const prizeTextWeekly = `${activeRate.display.prepend}${Math.floor(prizeWeekly)}K`;
    const prizeTextMonthly = `${activeRate.display.prepend}${Math.floor(prizeMonthly)}K`;
    return {
      weekly: prizeWeekly,
      monthly: prizeMonthly,
      text: {
        weekly: prizeTextWeekly,
        monthly: prizeTextMonthly
      }
    };
  }, [data, activeRate]);
  const img = useMemo(() => mini ? <>
          <Image src={outline.src} alt="Golden Laurel Crown" fill sizes="100px" />
          <Image src={bg.src} alt="Golden Laurel Crown" fill sizes="100px" />
        </> : <WeeklyIcon />, [mini]);
  const timeLeftWeekly = timeLeft(currentTime, setHours(setMinutes(setSeconds(nextSaturday(currentTime), -1), 0), 0));
  const timeLeftMonthly = timeLeft(currentTime, startOfMonth(addMonths(currentTime, 1)));
  const timeRemaining = type === "weekly" ? timeLeftWeekly : timeLeftMonthly;
  const day = L(timeRemaining.days === 1 ? "day" : "days") as string;
  const formatted = useMemo(() => {
    if (timeRemaining.days > 0) {
      return `${timeRemaining.days} ${day}`;
    } else {
      const hours = String(timeRemaining.hours).padStart(2, "0");
      const minutes = String(timeRemaining.minutes).padStart(2, "0");
      const seconds = String(timeRemaining.seconds).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    }
  }, [timeRemaining, day]);
  return <Link className={st.cta} href={type === "weekly" ? `/weekly-race` : `/monthly-race`} data-sentry-element="Link" data-sentry-component="RacesCTA" data-sentry-source-file="races-cta.tsx">
      <div className={st.container_text}>
        <div className={st.img}>{img}</div>
        <span className={st.number}>{prizes.text[type]}</span>

        {timeRemaining.total > 0 ? <div className={st.countdown_container}>
            <p className={st.countdown}>{formatted}</p>
          </div> : null}
      </div>

      <div className={st.text}>{L(`${type}_race`)}</div>
    </Link>;
};
export default memo(RacesCTA);