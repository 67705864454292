import useLanguages from "@hooks/use-languages";
import { useUserSession } from "@hooks/useUserSession";
import { cn } from "@lib/utils";
import API from "@lib/api/api";
import { useLocale } from "@lib/tools/helpers";
import { log } from "@lib/tools/logger";
import Cookies from "js-cookie";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "./new-ui/select";
import useUser from "@hooks/useUser";
import { chatLanguageAtom } from "@store/global";
import { useAtom } from "jotai";
export default function Language(props) {
  const {
    mini = false
  } = props;
  const {
    data: langs,
    isLoading,
    currentLanguage
  } = useLanguages();
  const locale = useLocale();
  const pathname = usePathname();
  const {
    hasToken,
    addTokenHeader
  } = useUserSession();
  const router = useRouter();
  const {
    data: user
  } = useUser();
  const userLocale = user?.preferences?.language;
  const [, setChatLanguage] = useAtom(chatLanguageAtom);
  const setLocaleApi = useCallback((language: string) => hasToken && API.post("user/update-settings", {
    language
  }, addTokenHeader()).then(r => r.data).then(({
    success
  }) => success && log("switched language to", language)), [hasToken, addTokenHeader]);
  const changeLanguage = useCallback(async (key: string) => {
    setLocaleApi(key);
    Cookies.set("lang", key);
  }, [setLocaleApi]);
  useEffect(() => {
    if (userLocale !== locale) changeLanguage(locale);
  }, [changeLanguage, locale, userLocale]);

  // Set the language and translation attributes on the html element
  useEffect(() => {
    if (typeof document === "undefined") return;
    document.documentElement.lang = locale;
    if (locale !== "en") {
      document.documentElement.setAttribute("translate", "no");
    } else {
      document.documentElement.removeAttribute("translate");
    }
  }, [locale]);
  const handleLanguageChange = useCallback((newLang: string) => {
    setChatLanguage(newLang);
    router.replace(pathname, router.asPath, {
      shallow: true,
      locale: newLang
    });
  }, [router, pathname, setChatLanguage]);
  return <Select value={locale} onValueChange={handleLanguageChange} data-sentry-element="Select" data-sentry-component="Language" data-sentry-source-file="language-dropdown.tsx">
      <SelectTrigger showChevron={!mini && !isLoading} className={cn("w-full", mini ? "p-0 justify-center h-[36px] min-h-[36px] w-[36px]" : "min-h-[42px] h-[42px]")} data-sentry-element="SelectTrigger" data-sentry-source-file="language-dropdown.tsx">
        {isLoading ? <div className={cn("h-5 w-full bg-gray-600/50 animate-pulse rounded", mini && "h-[36px]")}></div> : <SelectValue>
            <div className={cn("flex items-center gap-4", mini && "gap-0")}>
              <Image alt="flag" src={currentLanguage?.icon} width={16} height={16} className="rounded-full" />
              <span className="text-[#ced1df] hover:text-white text-[13px] truncate">
                {!mini ? currentLanguage?.name : null}
              </span>
            </div>
          </SelectValue>}
      </SelectTrigger>
      <SelectContent className={cn("z-[10000]  w-[209px]", mini && "min-w-[160px]")} data-sentry-element="SelectContent" data-sentry-source-file="language-dropdown.tsx">
        <SelectGroup data-sentry-element="SelectGroup" data-sentry-source-file="language-dropdown.tsx">
          {langs?.map(lang => <SelectItem key={lang.code} value={lang.code}>
              <div className="flex items-center gap-4">
                <Image alt="flag" src={lang.icon} width={16} height={16} loading="lazy" className="rounded-full" />
                <span className="text-[13px] truncate">{lang.name}</span>
              </div>
            </SelectItem>)}
        </SelectGroup>
      </SelectContent>
    </Select>;
}