import RaffleSideBar from "@Games/Raffle/components/raffle-side-bar";
import RaffleMiniSideBar from "@Games/Raffle/components/raffle-side-bar/raffle-mini-side-bar";
import { raffleDetailsAtom } from "@Games/Raffle/jotai-raffle";
import useMessages from "@hooks/use-messages";
import useLanguage from "@hooks/useLanguage";
import { useBreakpoint } from "@hooks/useMobile";
import useNow from "@hooks/useNow";
import { useUserSession } from "@hooks/useUserSession";
import { show } from "@intercom/messenger-js-sdk";
import Button from "@items/Button";
import FlyoutMenu from "@items/FlyoutMenu";
import GamesIcons from "@items/games-icons";
import ToolTip from "@items/ToolTip";
import { liveBaccaratLink, liveBlackjackLink, liveGameShowsLink, liveLink, liveRouletteLink, originalsLink, slotsLink } from "@lib/links";
import { classnames, resolveDomain, useLocale } from "@lib/tools/helpers";
import { inHouseGames } from "@lib/tools/house-games";
import { timeLeft } from "@lib/utils";
import { menuToggleAtom } from "@store/global";
import st from "@styles/components/SideBar.module.scss";
import { useAtom, useAtomValue } from "jotai";
import dynamic from "next/dynamic";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Accordion from "./Accordion";
import { CasinoSportsButtons } from "./header";
import Language from "./language-dropdown";
import RacesCTA from "./races-cta";
import FlyoutSportsContent from "./SideMenu/FlyoutSportsContent";
import SportsList from "./SideMenu/SportList";
import { ChallengeSvg } from "./Svg/SvgStore";
import DictionarySchema from "@dictionary";
const Baccarat = dynamic(() => import("@assets/icons/games/Baccarat"));
const BlackJack = dynamic(() => import("@assets/icons/games/Blackjack"));
const GameShowIcon = dynamic(() => import("@assets/icons/games/GameShowIcon"));
const LiveGamesIcon = dynamic(() => import("@assets/icons/games/LiveGamesIcon"));
const RouletteSlots = dynamic(() => import("@assets/icons/games/RouletteSlots"));
const BlogIcon = dynamic(() => import("@assets/icons/general/BlogIcon"));
const CasinoIconNew = dynamic(() => import("@assets/icons/general/CasinoIcon").then(mod => mod.CasinoIconNew));
const GiftIcon = dynamic(() => import("@assets/icons/general/GiftIcon"));
const LiveSupport = dynamic(() => import("@assets/icons/general/LiveSupport"));
const LoginIcon = dynamic(() => import("@assets/icons/general/LoginIcon"));
const NewLogoIcon = dynamic(() => import("@assets/icons/general/NewLogo").then(mod => mod.NewLogoIcon));
const PromotionsIconNav = dynamic(() => import("@assets/icons/general/PromotionsIconNav"));
const RegisterIcon = dynamic(() => import("@assets/icons/general/RegisterIcon"));
const SearchIcon = dynamic(() => import("@assets/icons/general/SearchIcon"));
const SportsIcon = dynamic(() => import("@assets/icons/general/Sports"));
const LiveBR = dynamic(() => import("@assets/icons/sports/Live").then(mod => mod.LiveBR));
const MyBets = dynamic(() => import("@assets/icons/sports/MyBets").then(mod => mod.MyBets));
const Soccer = dynamic(() => import("@assets/icons/sports/Soccer"));
const SportsFav = dynamic(() => import("@assets/icons/sports/SportsFav"));
const sideMenuNav = ["side-menu-nav"];
const CasinoGames = ({
  activeItem,
  setActiveItem
}: {
  activeItem: string;
  setActiveItem: (item: string) => void;
}) => {
  const L = useLanguage(["common", "Rewards", "Slots"]);
  return <>
      <Button buttonType={"link"} url={{
      pathname: "/casino/slots/"
    }} classType={sideMenuNav} text={L("slots")} showActive={true} icon={<svg id="Icons" width="20" height="20" version="1.1" viewBox="0 0 32 32">
            <path d="M29.8,6.2l-3-3.9C26.5,2,26,1.9,25.6,2.1l-1.2,0.6c-2.9,1.4-6.1,1.7-9.2,0.8c-1.3-0.4-2.7-0.5-4-0.5H5C4.4,3,4,3.4,4,4v11.6  c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1c0-1.6,1.3-2.9,3-2.9h2.9l-1.5,1.8c-3.5,4.2-6,9-7.4,14.3C7,29,7,29.4,7.2,29.6S7.7,30,8,30h13  c0.6,0,1-0.4,1-1c0-5.5,1.5-10.9,4.3-15.7l3.5-6C30.1,7,30,6.6,29.8,6.2z" />
          </svg>} activeItem={activeItem === "casino-slots"} method={() => setActiveItem("casino-slots")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
      <Button buttonType={"link"} url={{
      pathname: "/casino/live/blackjack/"
    }} classType={sideMenuNav} text={"Blackjack"} showActive={true} icon={<BlackJack />} activeItem={activeItem === "casino-blackjack"} method={() => setActiveItem("casino-blackjack")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
      <Button buttonType={"link"} url={{
      pathname: "/casino/live/baccarat/"
    }} classType={sideMenuNav} text={"Baccarat"} showActive={true} icon={<Baccarat />} activeItem={activeItem === "casino-baccarat"} method={() => setActiveItem("casino-baccarat")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
      <Button buttonType={"link"} url={{
      pathname: "/casino/live/roulette/"
    }} classType={sideMenuNav} text={L("roulette")} showActive={true} icon={<RouletteSlots />} activeItem={activeItem === "casino-roulette"} method={() => setActiveItem("casino-roulette")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
      <Button buttonType={"link"} url={{
      pathname: "/casino/live/game-shows/"
    }} classType={sideMenuNav} text={L("live_games")} showActive={true} icon={<GameShowIcon />} activeItem={activeItem === "casino-game-shows"} method={() => setActiveItem("casino-game-shows")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
      <Button buttonType={"link"} url={{
      pathname: "/casino/live/"
    }} classType={sideMenuNav} text={L("live_casino")} showActive={true} icon={<LiveGamesIcon />} activeItem={activeItem === "casino-live-casino"} method={() => setActiveItem("casino-live-casino")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
    </>;
};
const RainbetOriginals = ({
  miniSide,
  activeItem,
  setActiveItem
}) => {
  const format = useMessages();
  return inHouseGames.map(item => {
    return <Button key={item.name} buttonType={"link"} url={{
      pathname: `/casino/originals/${item.url}`
    }} classType={["side-menu-nav", item?.new && "new-game", miniSide ? "originals" : null]} text={format(item.name as keyof DictionarySchema)} icon={<GamesIcons code={item.url === "mines-game" ? "mines" : item.url} />} showActive={true} activeItem={activeItem === item.url} method={() => setActiveItem(item.url)} extraContent={item?.new && <span className={st["new-icon-default"]}>
              {format("common_new-game")}
            </span>} />;
  });
};
const useScrollPosition = ref => {
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(false);
  const handleScroll = useCallback(() => {
    if (ref.current) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight
      } = ref.current;
      setIsTop(scrollTop === 0);
      setIsBottom(scrollTop + clientHeight >= scrollHeight);
    }
  }, [ref]);
  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll, ref]);
  return {
    isTop,
    isBottom
  };
};
type NavSideBarProps = Readonly<{
  miniSide?: boolean;
}>;
export default function NavSideBar({
  miniSide
}: NavSideBarProps) {
  const {
    hasUserData,
    hasToken
  } = useUserSession();
  const L = useLanguage(["common", "Rewards", "Slots"]);
  const pathname = usePathname() || "";
  const locale_ = useLocale();
  const locale = locale_ !== "en" ? locale_ : "";
  const isMobile = useBreakpoint({
    breakPointThree: 390
  });
  const [menuToggle, mobileToggle] = useAtom(menuToggleAtom);
  const now = useNow();
  const raffleDetails = useAtomValue(raffleDetailsAtom);
  const menuState = useMemo(() => {
    if (pathname.includes("sportsbook-rules")) {
      return "games";
    } else if (pathname.includes("sports")) {
      return "sports";
    } else {
      return "games";
    }
  }, [pathname]);
  useEffect(() => {
    mobileToggle(false);
  }, [pathname, mobileToggle]);
  const [activeItem, setActiveItem] = useState(null);
  const [activeCasinoFlyout, setActiveCasinoFlyout] = useState(false);
  const [activeSportsFlyout, setActiveSportsFlyout] = useState(false);
  const [activeOriginalFlyout, setActiveOriginalFlyout] = useState(false);
  const casinoGamesRef = useRef(null);
  const {
    isTop,
    isBottom
  } = useScrollPosition(casinoGamesRef);
  useEffect(() => {
    switch (true) {
      case pathname.includes(slotsLink):
        setActiveItem("casino-slots");
        break;
      case pathname.includes(liveBlackjackLink):
        setActiveItem("casino-blackjack");
        break;
      case pathname.includes(liveBaccaratLink):
        setActiveItem("casino-baccarat");
        break;
      case pathname.includes(liveRouletteLink):
        setActiveItem("casino-roulette");
        break;
      case pathname.includes(liveGameShowsLink):
        setActiveItem("casino-game-shows");
        break;
      case pathname.includes(liveLink):
        setActiveItem("casino-live-casino");
        break;
      case pathname.includes(originalsLink):
        {
          const original = inHouseGames.find(item => pathname.includes(item.url));
          if (original) {
            setActiveItem(original.url);
          }
          break;
        }
      default:
        setActiveItem(null);
    }
  }, [pathname]);
  return <div data-sidebar={miniSide} {...classnames(st, "side-bar-container", miniSide ? "mini" : null, menuToggle ? "menu-toggle" : null)} style={{
    borderRight: "1px solid rgba(255, 255, 255, 0.05)"
  }} data-sentry-component="NavSideBar" data-sentry-source-file="sidebar.tsx">
      <div className={st["large-version"]}>
        <div className={st["login-register"]}>
          {!hasUserData && isMobile > 2 && <>
              <Button key={"login-side"} buttonType={"link"} query={{
            modal: "auth",
            tab: "login"
          }} classType={["side-menu-nav"]} text={L("login")} icon={<LoginIcon />} />
              <Button key={"register-side"} buttonType={"link"} query={{
            modal: "auth",
            tab: "register"
          }} classType={["side-menu-nav"]} text={L("register")} icon={<RegisterIcon />} />
            </>}

          <Button key={"register-side"} buttonType={"link"} query={{
          modal: "search"
        }} classType={["side-menu-nav"]} text={L("search")} icon={<SearchIcon />} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
        </div>
        {!isTop && <div className={`${st["gradient"]} ${st["gradient__top"]}`} style={isMobile === 3 && hasUserData ? {
        top: "65px"
      } : null} />}
        <div className={`${st["casino-games"]} ${st["scrolling-content"]} ${!hasUserData ? st["space-for-login"] : ""}`} ref={casinoGamesRef}>
          <div className={st["game-list"]}>
            <RaffleSideBar leftTime={timeLeft(now, raffleDetails?.ends_at?.replace("Z", ""))} data-sentry-element="RaffleSideBar" data-sentry-source-file="sidebar.tsx" />

            {menuState === "games" && <>
                <Accordion title={L("casino_games")} icon={<CasinoIconNew />} classType={["first-of"]} defaultClosed={!hasToken}>
                  <CasinoGames activeItem={activeItem} setActiveItem={setActiveItem} />
                </Accordion>

                <Accordion title={L("rainbetGames")} customFill={false} icon={<NewLogoIcon height="20" width="20" />} defaultClosed={!hasToken}>
                  <RainbetOriginals miniSide={miniSide} activeItem={activeItem} setActiveItem={setActiveItem} />
                </Accordion>
              </>}

            {menuState === "sports" && <>
                <div className=" [&_a]:max-w-[197px] flex flex-col gap-1 mb-1.5">
                  <Button url={{
                pathname: "/sportsbook/bets"
              }} buttonType="link" classType={["side-menu-nav"]} text={L("my-bets")} icon={<MyBets />} activeItem={pathname.includes("/bets")} />

                  <Button url={{
                pathname: "/sportsbook/live"
              }} buttonType="link" classType={["side-menu-nav"]} text={L("live-matches")} icon={<LiveBR />} activeItem={pathname.includes("/live")} />

                  {hasUserData && <Button url={{
                pathname: "/sportsbook/favorites"
              }} buttonType="link" classType={["side-menu-nav"]} text={L("Favorites")} icon={<SportsFav className="w-6 h-6" />} activeItem={pathname.includes("/favorites")} />}
                </div>

                <Accordion title={L("sports")} icon={<SportsIcon />} defaultClosed={false}>
                  <SportsList mobileToggle={mobileToggle} activeItem={activeItem} setActiveItem={setActiveItem} mini={miniSide} />
                </Accordion>
              </>}
          </div>

          <div className={st["sized-bar"]}></div>

          <div className={st["fixed-menu-items"]}>
            {locale === "pt" && <Link href="/sportsbook/soccer/brazil/brasileiro-serie-a-1669818812230406144" locale="pt" className={st.sportLink}>
                <Soccer style={{
              width: 15,
              marginRight: 10
            }} />
                Serie A Brasil
                <span>🔥</span>
              </Link>}

            <div className="px-[15px] space-y-[10px] mb-[10px]">
              <RacesCTA type="weekly" currentTime={now} data-sentry-element="RacesCTA" data-sentry-source-file="sidebar.tsx" />
              <RacesCTA type="monthly" currentTime={now} data-sentry-element="RacesCTA" data-sentry-source-file="sidebar.tsx" />
            </div>

            <div className={st["bottom-buttons-nav"]}>
              <Button buttonType={"link"} key={"Key25"} url={{
              pathname: "/rewards"
            }} classType={["side-menu-nav"]} text={L("rewards")} icon={<GiftIcon />} activeItem={pathname.includes("/rewards")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />

              <Button buttonType={"link"} url={{
              pathname: "/promotions"
            }} classType={["side-menu-nav"]} text={L("promotions")} icon={<PromotionsIconNav />} activeItem={pathname.includes("/promotions")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />

              <Button buttonType={"link"} url={{
              pathname: "/challenges"
            }} classType={["side-menu-nav"]} text={L("challenges")} icon={<ChallengeSvg width="20px" height="20px" />} activeItem={pathname.includes("/challenges")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
              <Button buttonType={"link"} url={{
              pathname: resolveDomain("blog") + locale
            }} classType={["side-menu-nav"]} text={L("blog")} icon={<BlogIcon />} activeItem={pathname.includes("/blog")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
            </div>
            <div className={st["promotions-button"]}>
              <div className={st["rewards-container"]}></div>
              <div className={st["rewards-container"]}>
                <Button key={9} method={() => show()} classType={["side-menu-nav"]} text={L("liveSupport")} icon={<LiveSupport />} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
              </div>
            </div>
          </div>
        </div>
        {!isBottom && <div className={`${st["gradient"]} ${st["gradient__bottom"]}`}></div>}
        <div className={`
                ${st["sidebar-footer"]}
            `}>
          <div className={st["lang-back"]}>
            <Language data-sentry-element="Language" data-sentry-source-file="sidebar.tsx" />
          </div>
        </div>
      </div>

      {/* MINI VERSION  --------------------------------------------------- MINI VERSION */}

      <div className={st["mini-version"]}>
        <div className={`${st["game-list"]} `}>
          <CasinoSportsButtons miniVersion={true} data-sentry-element="CasinoSportsButtons" data-sentry-source-file="sidebar.tsx" />
          <RaffleMiniSideBar data-sentry-element="RaffleMiniSideBar" data-sentry-source-file="sidebar.tsx" />
          <FlyoutMenu extraClasses="" setActiveFlyout={setActiveCasinoFlyout} content={<CasinoGames activeItem={activeItem} setActiveItem={setActiveItem} />} data-sentry-element="FlyoutMenu" data-sentry-source-file="sidebar.tsx">
            <Button url={{
            pathname: "/casino/"
          }} buttonType={"link"} classType={["side-menu-nav", "mini-version"]}
          // text={L("casino")}
          icon={<CasinoIconNew />} activeItem={menuState === "games" && !pathname.includes("/promotions") && !pathname.includes("/rewards") && !pathname.includes("/originals") && !pathname.includes("/challenges") && activeCasinoFlyout}
          // iconRight={"arrow"}
          data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
          </FlyoutMenu>

          <FlyoutMenu setActiveFlyout={setActiveSportsFlyout} extraClasses="" content={<FlyoutSportsContent miniSide={true} mobileToggle={mobileToggle} />} data-sentry-element="FlyoutMenu" data-sentry-source-file="sidebar.tsx">
            <Button url={{
            pathname: "/sportsbook"
          }} buttonType={"link"} classType={["side-menu-nav", "mini-version"]} icon={"sports"} activeItem={pathname.includes("sports") || activeSportsFlyout} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
          </FlyoutMenu>

          <FlyoutMenu extraClasses="" setActiveFlyout={setActiveOriginalFlyout} content={<RainbetOriginals miniSide={true} activeItem={activeItem} setActiveItem={setActiveItem} />} data-sentry-element="FlyoutMenu" data-sentry-source-file="sidebar.tsx">
            <Button url={{
            pathname: "/casino/originals"
          }} buttonType={"link"} classType={["side-menu-nav", "mini-version"]} icon={<NewLogoIcon width="20px" height="20px" />} activeItem={pathname.includes("/originals") || activeOriginalFlyout} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
          </FlyoutMenu>
        </div>

        <div className={st["scrolling-content"]}>
          <ToolTip placement="right" size={"small"} text={L("promotions")} transparent={undefined} data-sentry-element="ToolTip" data-sentry-source-file="sidebar.tsx">
            <Button buttonType={"link"} url={{
            pathname: "/promotions"
          }} classType={["side-menu-nav", "mini-version"]} icon={<PromotionsIconNav />} activeItem={pathname.includes("/promotions")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
          </ToolTip>

          <ToolTip placement="right" size={"small"} text={L("challenges")} transparent={undefined} data-sentry-element="ToolTip" data-sentry-source-file="sidebar.tsx">
            <Button buttonType={"link"} url={{
            pathname: "/challenges"
          }} classType={["side-menu-nav", "mini-version"]} icon={<ChallengeSvg width="20px" height="20px" />} activeItem={pathname.includes("/challenges")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
          </ToolTip>

          <ToolTip placement="right" size={"small"} text={L("blog")} transparent={undefined} data-sentry-element="ToolTip" data-sentry-source-file="sidebar.tsx">
            <Button buttonType={"link"} url={{
            pathname: resolveDomain("blog") + locale
          }} classType={["side-menu-nav", "mini-version"]} icon={<BlogIcon />} activeItem={pathname.includes("/blog")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
          </ToolTip>
          <ToolTip placement="right" size={"small"} text={L("rewards")} transparent={undefined} data-sentry-element="ToolTip" data-sentry-source-file="sidebar.tsx">
            <Button key={"test3234"} buttonType={"link"} url={{
            pathname: "/rewards"
          }} classType={["side-menu-nav", "mini-version"]} icon={<GiftIcon />} activeItem={pathname.includes("/rewards")} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
          </ToolTip>

          <ToolTip placement="right" size={"small"} text={L("liveSupport")} transparent={undefined} data-sentry-element="ToolTip" data-sentry-source-file="sidebar.tsx">
            <Button method={() => show()} classType={["side-menu-nav", "mini-version"]} icon={<LiveSupport />} data-sentry-element="Button" data-sentry-source-file="sidebar.tsx" />
          </ToolTip>
        </div>

        <div className={st["sidebar-footer"]}>
          <div className={st["lang-back"]}>
            <Language mini={true} data-sentry-element="Language" data-sentry-source-file="sidebar.tsx" />
          </div>
        </div>
      </div>
    </div>;
}
export const isLiveCasinoActive = pathname => {
  if (pathname.endsWith("/casino/live")) return true;
  const excludePaths = ["/casino/live/blackjack", "/casino/live/baccarat", "/casino/live/roulette", "/casino/live/game-shows"];
  return pathname.includes("/casino/live/") && !excludePaths.some(excludePath => pathname.includes(excludePath));
};