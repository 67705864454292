export const slotsLink = "/casino/slots";
export const liveBlackjackLink = "/casino/live/blackjack";
export const liveBaccaratLink = "/casino/live/baccarat";
export const liveRouletteLink = "/casino/live/roulette";
export const liveGameShowsLink = "/casino/live/game-shows";
export const liveLink = "/casino/live";
export const originalsLink = "/casino/originals";

export const sidebarCasinoLinks = [
  slotsLink,
  liveBlackjackLink,
  liveBaccaratLink,
  liveRouletteLink,
  liveGameShowsLink,
  liveLink,
] as const;
