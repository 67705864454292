import React, { useState, useRef, useCallback } from "react";
import st from "@styles/components/Accordion.module.scss";
import DropdownArrow from "@assets/icons/general/DropdownArrow";
import gsap from "gsap";
import { classnames } from "@lib/tools/helpers";

/**
 * @deprecated
 */
export default function Accordion({
  icon,
  children,
  title,
  classType = [],
  defaultClosed,
  mergedBody = false,
  bg = "#212749",
  customFill = true
}) {
  const [isOpen, setIsOpen] = useState(defaultClosed ? false : true);
  const ref = useRef();
  const handleAccordionClick = useCallback(() => setIsOpen(prev => {
    const value = !prev;
    gsap.to(ref.current, {
      height: value ? "auto" : 0,
      duration: 0.2,
      paddingBottom: value ? 10 : 0,
      paddingTop: value ? 10 : 0,
      opacity: value ? 1 : 0
    });
    return value;
  }), []);
  return <div {...classnames(st, "accordion-container", ...classType)} style={{
    "--bg": bg
  }} data-sentry-component="Accordion" data-sentry-source-file="index.jsx">
      <div {...classnames(st, "accordion-title-container", isOpen && "open", mergedBody && "merged")} onClick={handleAccordionClick}>
        <div>
          {icon && <span {...classnames(st, customFill ? "default-svg" : "custom-svg")}>
              {icon}
            </span>}
          <div className={`${st["heading"]} ${customFill ? st["custom-align"] : ""}`}>
            {title}
          </div>
        </div>

        <div {...classnames(st, "arrow-icon-container", isOpen && "rotate")}>
          <DropdownArrow data-sentry-element="DropdownArrow" data-sentry-source-file="index.jsx" />
        </div>
      </div>

      <div {...classnames(st, "accordion-child-container", mergedBody && "merged", isOpen && "open")} ref={ref} style={{
      height: !defaultClosed ? "auto" : 0,
      paddingBottom: !defaultClosed ? 10 : 0,
      paddingTop: !defaultClosed ? 10 : 0,
      opacity: !defaultClosed ? 1 : 0
    }}>
        {children}
      </div>
    </div>;
}