import st from "./raffle-side-bar.module.scss";
import { memo } from "react";
import useRaffleConvert from "../../helpers/use-raffle-convert";
import MiniTicketIcon from "../../assets/mini-ticket.png";
import Image from "next/image";
import { useDict } from "@hooks/useLanguage";
import Link from "next/link";

// Separate type definition for props
interface RaffleSideBarProps {
  leftTime?: {
    total: number;
    days: number;
    hours: number;
  };
}

// Separate component for displaying time
const TimeDisplay = ({
  leftTime
}) => {
  const dict = useDict(["raffle"]);
  if (!leftTime || leftTime.total <= 0) {
    return <span>{dict({
        name: "soon",
        as: "span"
      })}</span>;
  }
  const {
    days,
    hours
  } = leftTime;
  if (days > 0) {
    return <span>
        {days} {dict({
        name: days === 1 ? "day" : "days",
        as: "span"
      })}
      </span>;
  }
  if (hours > 0) {
    return <span>{hours} H</span>;
  }
  return <span data-sentry-component="TimeDisplay" data-sentry-source-file="index.tsx">{dict({
      name: "soon",
      as: "span"
    })}</span>;
};
function RaffleSideBar({
  leftTime
}: RaffleSideBarProps) {
  const dict = useDict(["raffle"]);
  const convertRaffleValue = useRaffleConvert();
  return <Link href="/raffle" className={st["raffle-side-bar-container"]} data-sentry-element="Link" data-sentry-component="RaffleSideBar" data-sentry-source-file="index.tsx">
      <div className={st["left-side"]}>
        <div className={st["icon-container"]}>
          <Image src={MiniTicketIcon} width={25} alt="mini-ticket-icon" priority data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </div>
        <div className={st["heading-container"]}>
          <span className={st["title"]}>{convertRaffleValue(20000)}</span>
          {dict({
          name: "weekly_raffle",
          as: "span",
          className: st["sub-title"]
        })}
        </div>
      </div>
      <div className={st["right-side"]}>
        <div className={st["days-left-container"]}>
          <div className={st["days-left-container-inner"]}>
            <TimeDisplay leftTime={leftTime} data-sentry-element="TimeDisplay" data-sentry-source-file="index.tsx" />
          </div>
        </div>
      </div>
    </Link>;
}
export default memo(RaffleSideBar);